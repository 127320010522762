import { createGlobalStyle } from "styled-components"
import { normalize } from "styled-normalize"
import SourceSansProRegular from "fonts/SourceSansProRegular.ttf"
import SourceSansProLight from "fonts/SourceSansProLight.ttf"
import { theme } from "theme"

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'SourceSansPro';
    src: local("SourceSandProRegular"),
    url(${SourceSansProRegular}) format("truetype");
    font-weight: 400;
  }
  
  @font-face {
    font-family: 'SourceSansPro';
    src: local("SourceSandProLight"),
    url(${SourceSansProLight}) format("truetype");
    font-weight: 100;
  }

  ${normalize};
  
  html { 
    font-size: 62.5%; 
  }

  body {
    font-size: 16px;
    font-size: 1.6rem;
    margin: 0;
    font-family: SourceSansPro;
    color: ${theme.colors.black};
    background: ${theme.colors.black};
  }

  html, body, #root {
    height: 100%;
  }

  * {
  box-sizing: border-box;
  }

  h2 {
    margin: 0;
  }

  h3 {
    margin: 0;
  }
  
  h4 {
    margin: 0;
  }
  
  p {
    margin: 0;
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
    color: unset;
  }
`
