import { Box } from "components/Box";
import { Button } from "components/Button";
import styled, { css } from "styled-components/macro";
import { theme } from "theme";
import { keyframes } from "styled-components";
import { Alert } from "@material-ui/lab";

export const PortfolioTableWrapper = styled(Box)`
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: ${theme.colors.white};
  padding: 30px;

  @media all and (max-width: 500px) {
    padding: 0px;
  }

  th {
    text-align: left;
    font-size: 13px;
    line-height: 20px;
    color: ${theme.colors.grey};
    font-weight: normal;
  }

  td {
    font-weight: bold;
    &:first-child {
      padding-left: 15px;
    }
    &:nth-child(3) {
      color: ${theme.colors.primary};
    }
  }
`;

export const PortfolioTableMobileWrapper = styled(Box)`
  overflow-x: auto;

  th {
    padding-left: 10px;
  }

  td:not(:first-child) {
    padding-left: 10px;
  }
`;

export const PortfolioChartWrapper = styled(Box)`
  box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  background: ${theme.colors.white};
  table {
    width: 100%;
  }

  th {
    text-align: left;
    font-size: 13px;
    line-height: 20px;
    color: ${theme.colors.grey};
    font-weight: normal;

    &:last-child {
      text-align: right;
    }
  }

  @media all and (max-width: 500px) {
    display: none;
  }
`;

export const PortfolioChartMobileWrapper = styled(Box)`
  @media all and (max-width: 500px) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: 0;

    thead {
      display: flex;
      flex-direction: column;
    }

    th {
      font-weight: normal;
    }
  }

  @media all and (min-width: 501px) {
    display: none;
  }
`;

export const PortfolioGraphMobileWrapper = styled(Box)`
  @media all and (max-width: 500px) {
    display: flex;
    justify-content: center;
  }

  @media all and (min-width: 501px) {
    display: none;
  }
`;

export const PortfolioGraphWrapper = styled(Box)`
  @media all and (max-width: 500px) {
    display: none;
  }

  @media all and (min-width: 501px) {
    display: flex;
  }
`;

export const TableBtn = styled(Button)`
  width: 43px;
  padding: 5px 10px;
  margin-right: 3px;
  border: 1px solid ${theme.colors.grey};
  color: ${theme.colors.black};
  background: none;

  ${(p) =>
    p.active &&
    css`
      color: ${theme.colors.black};
      font-weight: bold;
      border-color: ${theme.colors.black};
    `}
`;
const breatheAnimation = keyframes`
0% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
}

70% {
  transform: scale(1);
  box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
}

100% {
  transform: scale(0.95);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
}`;

export const LiveBtn = styled(Button)`
  background: rgba(255, 82, 82, 1);
  box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
  animation: ${breatheAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
  width: 43px;
  padding: 5px 10px;
  margin-right: 3px;
  border: 1px solid rgba(255, 82, 82, 1);
  color: rgba(255, 82, 82, 1);
  background: none;

  ${(p) =>
    p.active &&
    css`
      color: rgba(255, 82, 82, 1);
      font-weight: bold;
      border-color: rgba(255, 82, 82, 1);
    `}
`;

export const PortfolioWrapper = styled.div`
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const LoadingWrapper = styled.div`
  font-size: 2rem;
  margin-bottom: 2rem;
`;

export const CircularProgressWrapper = styled.div`
  color: #45b55b;
`;

export const StyledAlert = styled(Alert)`
  display: flex;
  align-items: center;
  font-size: 1.5rem !important;
  margin-bottom: 1.5rem;

  @media (max-width: 600px) {
    font-size: 1.2rem !important;
    margin: 0 0 0 1.2rem;
  }
`;
