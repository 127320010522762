import { Header } from "./Header"
import { PageWrapper, Content, Inner } from "./styled"

export const PageWithoutSidebar = ({ withGreeting, title, children }) => {
  return (
    <PageWrapper>
      <Header />
      <Content>
        <Inner>{children}</Inner>
      </Content>
    </PageWrapper>
  )
}
