import { css } from "styled-components/macro"
import { theme } from "theme"

export const margins = css`
  ${p => p.mt && `margin-top: ${p.mt}px`};
  ${p => p.mr && `margin-right: ${p.mr}px`};
  ${p => p.mb && `margin-bottom: ${p.mb}px`};
  ${p => p.ml && `margin-left: ${p.ml}px`};
  ${p => p.m && `margin: ${p.m}px`};
`
export const paddings = css`
  ${p => p.pt && `padding-top: ${p.pt}px`};
  ${p => p.pr && `padding-right: ${p.pr}px`};
  ${p => p.pb && `padding-bottom: ${p.pb}px`};
  ${p => p.pl && `padding-left: ${p.pl}px`};
  ${p => p.p && `padding: ${p.p}px`};
`

export const textAlign = css`
  text-align: ${p => p.tAlign};
`

export const fonts = css`
  font-size: ${p => p.fs || 16}px;
  ${p => p.bold && `font-weight: bold`};
  ${p => p.light && `font-weight: 100`};
  opacity: ${p => p.opacity || 1};
  ${p => p.lh && `line-height:${p.lh}px`};
  ${p => p.ls && `letter-spacing:${p.ls}px`};
  ${p => p.underline && `text-decoration: underline`};
  ${textAlign};
`

export const uppercase = css`
  ${p => p.uppercase && `text-transform: uppercase`};
`

export const flex = css`
  align-items: ${props => props.align};
  justify-content: ${props => props.justify};

  ${p =>
    p.flex &&
    `
    display: flex;
  `};

  ${p =>
    p.acenter &&
    `
    align-items: center;
  `};

  ${p =>
    p.spread &&
    `
    justify-content: space-between;
  `};

  ${p =>
    p.column &&
    `
    flex-direction: column;
  `};

  ${p =>
    p.wrap &&
    `
    flex-wrap: wrap;
    `};

  ${p =>
    p.even &&
    css`
      > * {
        flex: 1;
      }
    `}

  ${p =>
    p.grow &&
    css`
      flex: 1;
    `}
`

export const colors = css`
  ${p => p.color && `color: ${theme.colors[p.color] || p.color}`};
`
