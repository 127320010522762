import { Link as RouterLink } from "react-router-dom"
import { Box } from "components/Box"
import * as R from "ramda"

export const Link = ({ to, children, ...rest }) => {
  const link = <RouterLink to={to}>{children}</RouterLink>

  if (R.isEmpty(rest)) return link

  return <Box {...rest}>{link}</Box>
}
