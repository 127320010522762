import styled from 'styled-components/macro'
import { theme } from 'theme'

export const PageWrapper = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`

export const Content = styled.div`
  position: relative;
  background: ${theme.colors.white};
  z-index: 0;
  padding: 10px 0 10px;

  flex-grow: 1;

  ::after {
    content: '';
    width: 100%;
    height: 315px;
    background: ${theme.colors.lighterGrey};
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }
`
export const Inner = styled.div`
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px;
`
