import { Box } from "components/Box"
import styled from "styled-components/macro"
import { theme } from "theme"

export const HoverItemWrapper = styled(Box)`
  background: ${theme.colors.black};
  border-radius: 5px;
  color: ${theme.colors.white};
  width: 120px;
  p {
    font-size: 11px;
    line-height: 15px;
  }
`
