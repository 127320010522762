import moment from "moment"

export const daysOfTheWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

export const getAxisWeek = endDate => {
  let axisData = [...daysOfTheWeek]
  let subset = axisData.splice(endDate.getDay())
  return [...subset, ...axisData]
}

export const getAxisMonth = (startDate, endDate) => {
  let axis = [moment(startDate).format("DD.MM")]
  for (let index = 1; index < 5; index++) {
    axis.push(moment(startDate).add((index * 6), "days").format("DD.MM"))
  }
  axis.push(moment(endDate).format("DD.MM"))
  return axis
}

export const getAxisYear = (endDate) => {
  let axisData = [...months]
  let subset = axisData.splice(endDate.getMonth() + 1)
  return [...subset, ...axisData]
}

export const getAxis3Month = (startDate) => {
  let axisData = [...months]
  let subset = axisData.splice(startDate.getMonth() + 1, 3)
  if (subset.length < 3) {
    let rest = axisData.splice(0, 3 - subset.length)
    subset = [...subset, ...rest]
  }
  return [...subset]
}

export const getRandomColor = () => {
  var letters = "0123456789ABCDEF"
  var color = "#"
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)]
  }
  return color
}
