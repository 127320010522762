import styled from 'styled-components/macro'
import { theme } from 'theme'

export const ChangelogTableWrapper = styled.div`
  overflow-x: auto;
  th {
    text-align: left;
    font-size: 13px;
    line-height: 20px;
    color: ${theme.colors.grey};
    font-weight: normal;
    &:last-child {
      padding-right: 15px;
      text-align: right;
    }
  }

  table {
    border-collapse: separate;
    border-spacing: 0 10px;
    margin-top: -10px; /* correct offset on first border spacing if desired */
  }
  td {
    border: solid 1px #ebeaea;
    border-style: solid none;
    padding: 10px;
  }
  td:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  td:last-child {
    text-align: right;
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
`
