import styled, { css } from 'styled-components';

const Wrapper = styled.div`
    background: #F3F3F3;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const StatusWrapper = styled.div`
    font-size: 3rem;
    font-weight: bold;
`;

const ContentWrapper = styled.div`
    margin: 1.6rem 0;
    font-size: 1.6rem;

    ${({biggerFontSize}) => biggerFontSize && css`
        font-size: 2rem;
    `}
`;

const ErrorMessageWrapper = styled.span`
    font-size: 1.4rem;
    text-transform: uppercase;
    font-weight: bold;
`;

export { Wrapper, StatusWrapper, ContentWrapper, ErrorMessageWrapper }