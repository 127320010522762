import { flex, margins, paddings, textAlign } from "common/styles"
import styled from "styled-components/macro"

export const StyledBox = styled.div`
  ${p => p.relative && `position: relative`};
  ${flex};
  ${margins};
  ${paddings};
  ${textAlign};
`
