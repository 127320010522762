import * as R from "ramda"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { urls } from "pages/urls"
import { GlobalStyle } from "./GlobalStyle"

export const App = () => (
  <Router>
    <GlobalStyle />
      {R.values(urls).map(url => (
        <Route
          key={url.path}
          component={url.component}
          url={url.url}
          path={url.path}
        />
      ))}
  </Router>
)
