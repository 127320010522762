import { Fragment } from "react";
import { ExpandBox } from "../../../components/ExpandBox";
import { Table } from "../../../components/Table";
import { TableWrapper } from "./styled";

export const Rebalances = ({ dates }) => {
  return (
    <ExpandBox title="Rebalances" mt="30px" mb="15px">
      <TableWrapper>
        <Table
          headers={["Date"]}
          data={dates.map(date => ({ date }))}
          config={{
            columns: [
              {
                label: "date",
                path: (row) => <Fragment>{row.date}</Fragment>
              }
            ]
          }}
        />
      </TableWrapper>
    </ExpandBox>
  );
};
