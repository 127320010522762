import { Table } from "components/Table";
import { ExpandBox } from "../../../components/ExpandBox";
import { ChangelogTableWrapper } from "./styled";

export const Fees = (props) => {
  console.log(props.value);
  if (props.value) {
    return (
      <ExpandBox title="Fees" mt="30px" mb="15px">
        <ChangelogTableWrapper>
          <Table
            headers={["Date", "Type", "Management", "Success", "Total"]}
            data={props.value}
            config={{
              columns: [
                {
                  label: "date",
                  path: (x) => <>{x.date}</>
                },
                {
                  label: "type",
                  path: (x) => <>{x.value.fee_type ? x.value.fee_type : ""}</>
                },
                {
                  label: "management",
                  path: (x) => (
                    <>
                      {x.value.management_fee_usd
                        ? x.value.management_fee_usd
                        : ""}{" "}
                      $
                    </>
                  )
                },
                {
                  label: "success",
                  path: (x) => (
                    <>
                      {x.value.success_fee_usd ? x.value.success_fee_usd : ""} $
                    </>
                  )
                },
                {
                  label: "total",
                  path: (x) => (
                    <>
                      {x.value.total_sum_of_fees_usd
                        ? x.value.total_sum_of_fees_usd
                        : ""}{" "}
                      $
                    </>
                  )
                }
              ]
            }}
          />
        </ChangelogTableWrapper>
      </ExpandBox>
    );
  } else {
    return <></>;
  }
};
