import { StyledTable, StyledTr, StyledTd } from "./styled";

export const Table = ({ headers, config, data }) => (
  <StyledTable>
    <thead>
      <tr>
        {headers.map((header, headerI) => (
          <th key={headerI}>{header}</th>
        ))}
      </tr>
    </thead>
    <tbody>
      {data
        .filter((item) => item.coin !== 0)
        .map((row, rowI) => (
          <StyledTr key={rowI}>
            {config.columns.map((item, itemI) => (
              <StyledTd key={itemI}>{item.path(row)}</StyledTd>
            ))}
          </StyledTr>
        ))}
    </tbody>
  </StyledTable>
);
