import styled, { css } from "styled-components/macro";
import { theme } from "theme";
import { paddings, fonts, margins, colors } from "common/styles";

export const StyledButton = styled.button`
  padding: 10px 30px;
  border-radius: 0.4rem;
  border: none;
  text-align: center;
  cursor: pointer;
  background: ${(p) => (p.bg ? p.bg : theme.colors.primary)};
  color: ${(p) => (p.color ? p.color : theme.colors.white)};
  ${(p) => p.fullWidth && `width: 100%`};
  ${paddings};
  ${margins};
  ${fonts};
  ${colors};

  ${(p) =>
    p.secondary &&
    css`
       {
        color: ${theme.colors.primary};
        background: rgba(${theme.rgbColors.primary}, 0.1);
      }
    `}
  ${(p) =>
    p.border &&
    css`
       {
        border: 1px solid ${theme.colors[p.border]};
      }
    `}
  ${(p) =>
    p.width &&
    css`
       {
        width: 100%;
      }
    `}
`;
