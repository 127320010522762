import * as R from "ramda"

const breakpoints = {
  tablet: 768,
  desktop: 1024,
}

const hexToRgb = hex => {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
  const tmp = hex.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b)

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(tmp)
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ].join(",")
}

const colors = {
  primary: "#45B55B",
  white: "#fff",
  black: "#030303",
  error: "#DF2020",
  success: "#45B55B",
  lightGrey: "#EEF1EF",
  lighterGrey: "#F3F3F3",
  mediumGrey: "#999999",
  grey: "#949A9E",
}

const zIndices = {}

export const theme = {
  colors,
  rgbColors: R.map(hexToRgb, colors),
  zIndices,
  breakpoints,
  mq: R.map(x => `@media (min-width: ${x}px)`, breakpoints),
}
