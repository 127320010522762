import { Box } from "components/Box";
import { Button } from "components/Button";
import { PageWithoutSidebar } from "components/PageWithoutSidebar";
import { useState, useCallback, useEffect } from "react";
import { Text } from "components/Text";
import { Changelog } from "./Changelog";
import { Portfolio } from "./Portfolio";
import { Withdrawals } from "./Withdrawals";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Fees } from "./Fees";
import ErrorMessage from "components/ErrorMessage";
import { CircularProgress } from "@material-ui/core";
import {
  CircularProgressWrapper,
  LoadingWrapper,
  PortfolioWrapper,
} from "./Portfolio/styled";
import { Rebalances } from "./Rebalances";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const PortfolioPage = () => {
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [portfolioType, setportfolioType] = useState(null);
  const [withdrawals, setWithdrawals] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fees, setFees] = useState(null)

  let query = useQuery();
  const user = query.get("hash");
  const ignoreUserIds = ['a4fac6c5-6a85-4a48-75bd-a20dd6ebd906', '6c7fbaf9-2f19-48d4-519d-c5dd5917d1b9', '46359299-a3a7-40d0-7814-75b621411cea'];

  const loadData = useCallback(async () => {
    setIsLoading(true);

    const response = await axios
      .get(
        // API Chart & coins
        `https://show.hodlx.digital/v5/api/portfolio?hash=${query.get("hash")}&show_negative_bitcoin_values=no`
      )
      .catch((error) => {
        if (error.response) {
          setErrorMessage({
            data: error.response.data,
            status: error.response.status,
            message: 'Your portfolio is currently disabled'
          });
        }
      });
    if (response && response.status && response.status === 202) {
      setErrorMessage({
        data: response.data.status,
        status: response.status,
        message: 'Your portfolio is currently disabled on purpose by the support team. It will be enabled soon.'
      });
    }
    else if (!errorMessage && response?.data?.statistics?.portfolio_type) {
      setportfolioType(response.data.statistics.portfolio_type);
      setData(response);
      const feesData = response.data.events.fees
      if (feesData && Object.keys(feesData).length) {
        const valuesFees = Object.entries(feesData).map((record) => {
          return { date: record[0], value: record[1] }
        })
        setFees(valuesFees)
      }
      const withdrawalsData = response.data.events.withdrawals
      if (withdrawalsData && Object.keys(withdrawalsData).length) {
        const valuesW = Object.entries(withdrawalsData).map((record) => {
          return { date: record[0], value: record[1] }
        })
        setWithdrawals(valuesW)
      }
    }

    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);
  return (
    <>
      {errorMessage ? (
        <ErrorMessage
          errorMessage={errorMessage.message}
          errorStatus={errorMessage.status}
        />
      ) : isLoading ? (
        <PortfolioWrapper>
          <LoadingWrapper>Loading...</LoadingWrapper>
          <CircularProgressWrapper>
            <CircularProgress color="inherit" size={150} />
          </CircularProgressWrapper>
        </PortfolioWrapper>
      ) : (
        <>
          <PageWithoutSidebar>
            <Box flex spread>
              <Box flex>
                <Text fs={20} mr={5}>
                  {user}'s portfolio:
                </Text>
                <Text fs={20} bold>
                  {portfolioType === 'guarantee' ? 'Crypto Strategist' : (portfolioType ? portfolioType.toUpperCase() : '')}
                </Text>
              </Box>
              {/* <Button text="Calculate potential gain" fs={16} lh={20} /> */}
            </Box>
            <Portfolio response={data} />
            {withdrawals && <Withdrawals value={withdrawals} />}
            {fees && <Fees value={fees} />}
            {portfolioType !== 'guarantee' && !ignoreUserIds.includes(user) && <Rebalances dates={["2021-09-10", "2022-04-02", "2022-12-22", "2023-04-01", "2023-10-23", "2024-08-02"]} />}
            {portfolioType !== 'guarantee' && ignoreUserIds.includes(user) && <Rebalances dates={["2023-10-23"]} />}
          </PageWithoutSidebar>
        </>
      )}
    </>
  );
};
