import { Box } from "components/Box"
import styled from "styled-components/macro"
import { theme } from "theme"

export const HeaderWrapper = styled(Box)`
  height: 70px;
  color: ${theme.colors.white};
`
export const Inner = styled(Box)`
  max-width: 1170px;
  margin: 0 auto;
  padding: 20px;
`
