import { margins, paddings, uppercase, colors, fonts } from "common/styles"
import styled from "styled-components/macro"

export const StyledText = styled.p`
  ${fonts};
  ${uppercase};
  ${margins};
  ${paddings};
  ${colors};
`
