import { useLocation } from "react-router-dom"
import {Table} from "components/Table"
import {Text} from "components/Text"
import {ChangelogTableWrapper} from "./styled"
import {Icon} from "components/Icon"
import {MonetizationOn, Person} from "@material-ui/icons"
import {Box} from "components/Box"
import axios from "axios";
import {useEffect, useState} from "react";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Accordion from "@material-ui/core/Accordion";
import moment from "moment";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Changelog = () => {
  let query = useQuery();
  const [rebalances, setRebalances] = useState(null)
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(async () => {
    await axios.get(
      `/v3/api/rebalances?hash=${query.get("hash")}`,
    ).then(value => {
      let allRebalances = [];
      for (let key in value.data.rebalances) {
        let date = moment.unix(parseInt(key)).format("DD/MM/YYYY");
        allRebalances.push({
          id: Object.keys(value.data.rebalances).indexOf(key),
          date: date,
          rebalances: value.data.rebalances[key]
        });
      }
      setRebalances(allRebalances)
    })
  }, [])

  function sumValuesById(id) {
    let objOldById = rebalances.filter(x => x.id === id)[0].rebalances.portfolio_old_just_coins;
    let objNewById = rebalances.filter(x => x.id === id)[0].rebalances.portfolio_new_just_coins;
    let sumNew = Object.keys(objNewById).map((key) => objNewById[key]).reduce((a, b) => a + b, 0);
    let sumOld = Object.keys(objOldById).map((key) => objOldById[key]).reduce((a, b) => a + b, 0);
    return (sumNew - sumOld);
  }

  function accordionDetails(rebalances) {
    let oldRebalances = rebalances.portfolio_old_just_coins;
    let newRebalances = rebalances.portfolio_new_just_coins;
    let finalValues = [];
    let oldCoins = Object.keys(oldRebalances).map((key, id) => {
      return ({
        id: id,
        coin: key,
        value: oldRebalances[key]
      })
    })
    let newCoins = Object.keys(newRebalances).map((key, id) => {
      return ({
        id: id,
        coin: key,
        value: newRebalances[key]
      })
    })
    newCoins.forEach(value => {
      let oldValue = oldCoins.find(x => x.coin === value.coin)
      if (oldValue !== undefined) {
        finalValues.push({
          coin: value.coin,
          newValue: value.value,
          oldValue: oldValue.value,
          change: (value.value - oldValue.value)
        })
      } else {
        finalValues.push({coin: value.coin, newValue: value.value, oldValue: 0, change: (value.value)})
      }
    })
    oldCoins.forEach(value => {
      let isInFinal = finalValues.find(x => x.coin === value.coin)
      if (isInFinal === undefined) {
        finalValues.push({coin: value.coin,  newValue: 0, oldValue: value.value, change: (0 - value.value)})
      }
    })
    return finalValues;
  }

  function getColor(name) {
    return "white";
  }

  if (rebalances) {
    return (
      <>
        <Text fs={20} mb={15} lh={30}>
          Changelog
        </Text>
        <ChangelogTableWrapper>
          <Table
            headers={[
              <div className="headingWrapper">
                <div className="heading">
                  Date
                </div>
                <div className="secondaryHeading">
                  Summary
                </div>
              </div>
            ]}
            data={rebalances}
            config={{
              columns: [
                {
                  label: "change",
                  path: x => (
                    <Accordion key={x.id} style={{boxShadow: "none", width: "100%"}} expanded={expanded === 'panel' + x.id}
                               onChange={handleChange('panel' + x.id)}>
                      <AccordionSummary
                        style={{padding: "0"}}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                      >
                        <div className="headingWrapper">
                          <div className="heading">
                            {x.date}
                          </div>
                          <div className="secondaryHeading">
                            {sumValuesById(x.id).toFixed(2)}€
                          </div>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className="accordionDetails">
                        <div>
                          <table className="portfolioChanges">
                            <tbody>
                                <th>Coin</th>
                                <th>New value</th>
                                <th>Old value</th>
                                <th>Change</th>
                            {accordionDetails(x.rebalances).map((item, index) => (
                              <tr key={index}>
                                {Object.values(item).map((val, i) => (
                                  <td key={i}>{isNaN(val) ?
                                    <Box flex acenter justify="left">
                                      <Icon color={getColor(val)} mr={10} w={24} h={24}>
                                        <MonetizationOn />
                                      </Icon>
                                      {val}
                                    </Box>
                                    : val.toFixed(2)}</td>
                                ))}
                              </tr>
                            ))}
                            </tbody>
                          </table>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  )
                },
              ],
            }}
          />
        </ChangelogTableWrapper>
      </>
    )
  } else {
    return (
      <></>
    )
  }
}
