import Collapse from "@material-ui/core/Collapse";
import { Fragment, useState } from "react";
import { Text } from "../Text";
import { ExpandHeader, ExpandIcon } from "./styled";

/**
 * @param title {string}
 * @param children {React.ReactNode}
 * @param props {import('@material-ui/core/Box').BoxProps}
 * @returns {JSX.Element}
 * @constructor
 */
export function ExpandBox({ title, children, ...props }) {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = (event) => {
    setExpanded(!expanded);
    props.onClick?.(event);
  };

  return (
    <Fragment>
      <ExpandHeader {...props} onClick={handleToggle}>
        <Text fs={20} lh={30}>
          {title}
        </Text>
        <ExpandIcon fontSize="large" expanded={expanded} />
      </ExpandHeader>
      <Collapse in={expanded}>{children}</Collapse>
    </Fragment>
  );
}
