import styled from "styled-components/macro";
import { theme } from "theme";

export const TableWrapper = styled.div`
  th {
    color: ${theme.colors.grey};
    font-size: 13px;
    font-weight: normal;
    line-height: 20px;
    text-align: left;
  }

  td {
    padding: 10px;
  }
`;
