import styled, { css } from "styled-components/macro"
import { theme } from "theme"
import { margins } from "common/styles"

export const StyledIconWrapper = styled.div`
  width: ${props => props.w || 20}px;
  height: ${props => props.h || 20}px;
  ${margins};
  ${props =>
    props.onClick &&
    css`
      cursor: pointer;
    `}

  svg {
    width: ${props => props.w || 20}px;
    height: ${props => props.h || 20}px;
    fill: ${props =>
      props.color
        ? theme.colors[props.color] || props.color
        : theme.colors.white};
  }
`
