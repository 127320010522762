import { Text } from "components/Text"
import { GraphTextWrapper } from "./styled"
import { theme } from "theme"
import { PieChart, Pie, Cell } from "recharts"
import { Box } from "components/Box"

export const PortfolioGraph = ({ data, mobile, total }) => {
  const newData = data.map(x => ({
    name: x.name,
    value: Number(x.percent),
    color: x.color,
    valueTotal: Number(x.total)
  }))
  const totalValue = total
  return (
    <Box relative flex acenter justify="center" mr={mobile ? 0 : 40} mt={15}>
      <PieChart width={278} height={278} position="center">
        <Pie
          data={newData}
          dataKey="value"
          innerRadius="85%"
          outerRadius="100%"
          fill={theme.colors.lightGrey}
          startAngle={90}
          endAngle={-270}
        >
          {newData.map((x, xi) => (
            <Cell
              key="test"
              fill={x.color}
              stroke={theme.colors.white}
              strokeWidth={xi / 2}
            />
          ))}
        </Pie>
      </PieChart>
      <GraphTextWrapper flex acenter column>
        <Text color="grey" fs={13}>
          Total value
        </Text>
        <Text fs={39}>${totalValue.toFixed(2)}</Text>
      </GraphTextWrapper>
    </Box>
  )
}
