import React from "react"
import * as R from "ramda"
import { fromEvent } from "rxjs"
import { debounceTime } from "rxjs/operators"
import { theme } from "theme"

export const useMedia = () => {
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)
  const [isDesktop, setIsDesktop] = React.useState(true)
  const setFlags = () => {
    const w = window.innerWidth
    setIsMobile(w < theme.breakpoints.tablet)
    setIsTablet(w >= theme.breakpoints.tablet && w < theme.breakpoints.desktop)
    setIsDesktop(w >= theme.breakpoints.desktop)
  }
  React.useEffect(() => {
    setFlags()
    const sub = fromEvent(window, "resize")
      .pipe(debounceTime(200))
      .subscribe(() => setFlags())
    return () => sub.unsubscribe()
  }, [])

  const mq = (...vals) => {
    if (vals.length > 3) {
      throw Error(
        'We only have 3 media queries, so call to "mq" should have 3 arguments at most',
      )
    }
    const is = [isMobile, isTablet, isDesktop]
    const i = is.find(x => x)
    return vals.slice(i).find(x => !R.isNil(x))
  }
  return { mq, isMobile, isTablet, isDesktop }
}
