import { ArrowDropDown } from "@material-ui/icons"
import { Box } from "components/Box"
import { Icon } from "components/Icon"
import { Link } from "components/Link"
import { Text } from "components/Text"
import { HeaderWrapper, Inner } from "./styled"

var today = new Date();
var dd = String(today.getDate()).padStart(2, '0');
var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
var yyyy = today.getFullYear();

today = mm + '/' + dd + '/' + yyyy;

export const Header = () => (
  <HeaderWrapper>
    <Inner flex spread acenter>
      <Box>
        <Text fs={14} uppercase bold ls={8}>
          Crypton
        </Text>
        <Text fs={9} uppercase ls={5}>
          Digital
        </Text>
      </Box>
      <Box flex acenter>
        <Text>{today}</Text>
      </Box>
    </Inner>
  </HeaderWrapper>
)
