import Box from "@material-ui/core/Box";
import ExpandMore from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import { css } from "styled-components/macro";

export const ExpandHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

export const ExpandIcon = styled(ExpandMore)`
  ${({ expanded }) => css`
    transform: ${expanded ? "rotate(-180deg)" : "none"};
    transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  `}
`;
