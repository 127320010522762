import { Table } from "components/Table";
import { Text } from "components/Text";
import { useLocation } from "react-router-dom";
import {
  PortfolioTableWrapper,
  PortfolioChartWrapper,
  TableBtn,
  LiveBtn,
  PortfolioChartMobileWrapper,
  PortfolioGraphMobileWrapper,
  PortfolioGraphWrapper,
  PortfolioTableMobileWrapper,
  StyledAlert,
} from "./styled";
import { Icon } from "components/Icon";
import { MonetizationOn } from "@material-ui/icons";
import { Box } from "components/Box";
import { PortfolioGraph } from "./PortfolioGraph";
import { PortfolioChart } from "./PortfolioChart";
import { useMedia } from "hooks/useMedia";
import { useState, useCallback, useEffect } from "react";
import {
  getAxisWeek,
  getAxisMonth,
  getAxis3Month,
  getAxisYear,
} from "./chartDataHelpers";
import coinColors from "./coins.json";
import { LoadingSkeleton } from "components/LoadingSkeleton";
import { reconnectingSocket } from "./websocket";
import { Button } from "components/Button";

const URL = "wss://stream.binance.com:9443/ws";
const client = reconnectingSocket(URL);

const UPDATE_INTERVAL_MS = 10000;

export const Portfolio = (props) => {
  const response = props.response;
  const { isTablet, isDesktop } = useMedia();
  const [isLoading, setIsLoading] = useState(true);
  const [chartAxisValues, setChartAxisValues] = useState(null);
  const [chartData, setChartData] = useState(null);
  const [currentBalance, setCurrentBalance] = useState(0);
  const [chartAxisType, setChartAxisType] = useState("All");
  const [filteredChartData, setFilteredChartData] = useState(null);
  const [portfolioRebalanced, setPortfolioRebalanced] = useState();
  const [TimeValues, setTimeValues] = useState([]);
  const [currentChange, setCurrentChange] = useState(0);
  const [ChangePercentage, setCurrentChangePercentage] = useState(0);
  const [invested, setInvested] = useState(0);
  const [coinNames, setCoinNames] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [sumValues, setSumValues] = useState([]);
  const [message, setMessage] = useState("");
  const messages = useMessages();
  const [isConnected, setIsConnected] = useState(client.isConnected());
  const [dates, setDates] = useState([]);
  const [currentTime, setCurrentTime] = useState();
  const [currentDate, setCurrentDate] = useState();
  const [responseData, setResponseData] = useState();
  const [coinError, setCoinError] = useState(null);
  const [bitcoinData, setBitcoinData] = useState(null);
  const [currentYearUntilToday, setCurrentYearUntilToday] = useState(null);
  const [portfolioRebalancedTmp, setPortfolioRebalancedTmp] = useState();
  const [updateIntervalEnd, setUpdateIntervalEnd] = useState();
  const [portfolioType, setportfolioType] = useState(null);

  useEffect(() => {
    return client.onStateChange(setIsConnected);
  }, [setIsConnected]);

  useEffect(() => {
    const msg = {
      method: "SUBSCRIBE",
      params: coinNames,
      id: 1,
    };
    if (isConnected) {
      client.getClient().send(JSON.stringify(msg));
    }
  }, [isConnected]);

  useEffect(() => {
    let local = new Date();
    let localdatetime =
      local.getHours() +
      ":" +
      pad(local.getMinutes()) +
      ":" +
      pad(local.getSeconds());
    let finalValue = 0;
    if (liveData.length > 0) {
      var newList = bitcoinData.map(function (e) {
        return e.totalUsd ? e.totalUsd : 0;
      });
    }
    liveData.forEach((value) => {
      if (value.name + "t" === messages.coin) {
        const pos = liveData
          .map(function (e) {
            return e.name;
          })
          .indexOf(value.name);
        finalValue = messages.pv * value.pv;
        newList[pos] = finalValue;
        setSumValues(
          [
            ...sumValues,
            { name: localdatetime, pv: sumFinalValues(newList) },
          ].slice(-20)
        );
      }
    });

    if(portfolioRebalanced){
      let tmpPortfolio;
      const currentCoin = Object.entries(coinColors).find(coin => coin[0].toLowerCase() === messages.coin.slice(0,-4))
      if(currentCoin && currentCoin.length !== 0){
        const toBeChanged = portfolioRebalanced.find(coin => coin.shortName === currentCoin[1].name)

        if(toBeChanged){
          if(!portfolioRebalancedTmp){
            tmpPortfolio = portfolioRebalanced.map(coin => coin.shortName === toBeChanged.shortName ? {...coin, price: Number(messages.pv), totalUsd: Number(messages.pv) * coin.coins} : coin)
            let end = new Date()
            end.setMilliseconds(end.getMilliseconds() + UPDATE_INTERVAL_MS)
            setUpdateIntervalEnd(end)
          }else{
            tmpPortfolio = portfolioRebalancedTmp.map(coin => coin.shortName === toBeChanged.shortName ? {...coin, price: Number(messages.pv), totalUsd: Number(messages.pv) * coin.coins} : coin)
          }

          setPortfolioRebalancedTmp(tmpPortfolio)
          if(new Date() > updateIntervalEnd && portfolioRebalancedTmp){
            setPortfolioRebalanced(tmpPortfolio)
          }
        }
      }
    }

    if (chartAxisType === "Live") {
      setFilteredChartData(sumValues);
      if (sumValues[0].name !== undefined) {
        setChartAxisValues([
          sumValues[0].name,
          sumValues[sumValues.length - 1].name,
        ]);
        setGraphHeaderValues(newList);
      }
    } else {
      let values = filteredChartData;

      if (values && values[values.length - 1] !== undefined) {
        values[values.length - 1].pv = sumFinalValues(newList);
        setFilteredChartData(values);
        setGraphHeaderValues(newList);
      }
    }
  }, [messages]);

  useEffect(() => {
    setPortfolioRebalancedTmp(null)
    setUpdateIntervalEnd(null)
  },[portfolioRebalanced]);

  function setGraphHeaderValues(newList) {
    let sum = sumFinalValues(newList);

    const percent = () => {
      if (sum === 0 && invested === 0) {
        return 0;
      }

      if (sum < invested) {
        return -(100 - (sum / invested) * 100);
      }

      return (sum / invested - 1) * 100;
    };

    if( updateIntervalEnd && new Date() > updateIntervalEnd ){
      setCurrentBalance(sum);
      setCurrentChange(sum - invested);
      setCurrentChangePercentage(percent);
    }
  }

  function sumFinalValues(data) {
    return data.reduce(function (a, b) {
      return a + b;
    }, 0);
  }

  function pad(t) {
    var st = "" + t;

    while (st.length < 2) st = "0" + st;

    return st;
  }

  // method used for sending message to client (buy - sell positions...)
  function sendMessage(e) {
    e.preventDefault();
    client.getClient().send(message);
    setMessage("");
  }

  useEffect(() => {
    if (response) {
      const datesData = Object.entries(response.data.dates).map((record) => {
        return { name: record[0], rest: record[1] };
      });

      if (!currentTime) {
        return;
      } else {
        setCoinError(null);
      }

      const findCoinAccordingToCurrentTime = datesData.find(
        (item) => item.name === currentTime
      );

      const mapAvailableData = Object.values(
        findCoinAccordingToCurrentTime.rest.coins_holding
      ).map((coinValue) => {
        const NO_DATA_AVAILABLE = "no_data_available";

        if (coinValue.amount_holding !== NO_DATA_AVAILABLE) {
          return true;
        }
        return false;
      });

      const areDataAvailable = mapAvailableData.find((value) => value === true);

      if (!areDataAvailable) {
        setCoinError(
          `For selected date ${currentTime} there's no data. Please select another date.`
        );
        return;
      }

      const latestCoins = Object.values(response.data.dates).pop();
      const coins = currentTime
        ? findCoinAccordingToCurrentTime.rest.coins_holding
        : latestCoins.coins_holding;

      const getDateAndTotalValue = Object.entries(
        responseData.data.timeseries
      ).map((record) => ({
        date: record[0],
        totalValue: record[1],
      }));
      const findDateAccordingToCurrentTime = getDateAndTotalValue.find(
        (value) => value.date === currentTime
      );

      const totalValue = findDateAccordingToCurrentTime.totalValue;

      const coinHoldings = Object.entries(coins).map((x) => {
        i = i + 1;
        if (x[0] !== "statistics") {
          return {
            name: x[0].toLowerCase() + "usd",
            pv: Number(x[1].amount_holding.toFixed(2)),
            percent: Number(x[1].percentage_share_of_value_on_this_day)
          };
        }
      })

      coinHoldings.sort(function (a, b) {
        return b.percent - a.percent;
      })

      if (totalValue > 0) {
        setLiveData(coinHoldings);

        setCoinNames(
          coinHoldings.map((coin) => {
            return coin.name + "t@kline_1m";
          })
        );
      }

      delete coins.statistics;

      function typePortfolio(type) {
        if (type === "balance10") {
          return "BALANCE-10";
        } else if (type === "progressive10") {
          return "PLATFORM10";
        } else if (type === "aggresive10") {
          return "AGGRESSIVE";
        } else if (type === "dynamic10") {
          return "DYNAMIC-10";
        } else return type;
      }

      const type = typePortfolio(response.data.statistics.portfolio_type);

      var i = -1;
      var letter = null;

      const bitcoinTableData = Object.entries(coins).map((x) => {
        i = i + 1;
        if (type === "alt10") {
          letter = null;
        } else if (type === "top10") {
          letter = null;
        } else {
          letter = type.toUpperCase()[i];
        }
        if (x[0] !== "statistics") {
          const isValueString = isNaN(
            x[1].percentage_share_of_value_on_this_day
          );

          return {
            shortName: letter ? letter : coinColors[x[0]]?.name,
            coins: Number(x[1].amount_holding),
            percent: isValueString
              ? 0
              : Number(x[1].percentage_share_of_value_on_this_day),
            price: Number(x[1].price_usd),
            totalUsd: Number(x[1].total_value_usd),
            color: coinColors[x[0]]?.color,
            //name: coinColors[x[0]].name,
            total: totalValue,
          };
        } else {
          return {
            shortName: letter ? letter : coinColors[x[0]]?.name,
            coins: Number(x[1].amount_holding.toFixed(2)),
            percent: Number(0),
            price: Number(0),
            totalUsd: Number(0),
            color: coinColors[x[0]]?.color,
            total: totalValue,
          };
        }
      });

      const coinsData = bitcoinTableData.sort(function (a, b) {
        return b.percent - a.percent;
      }).slice(0,10)

      setCurrentBalance(totalValue);
      setPortfolioRebalanced(coinsData);
    }
  }, [currentTime]);

  const loadData = useCallback(async () => {
    setIsLoading(true);

    // const rebalances = await axios.get(
    //   // Api Changelog
    //   `https://show.hodlx.digital/v4/api/rebalances?hash=${query.get('hash')}`
    // )

    // const coins  = await axios.get('https://pro-api.coinmarketcap.com/v1/cryptocurrency/map',  {
    //   headers: {
    //     "X-CMC_PRO_API_KEY": "d1c9dd50-2066-4919-9fab-3a85dc3e3610"
    //   }
    // })

    function time(time) {
      const dateValue = Object.entries(time).map((date) => {
        return date[0];
      });

      const endDate = new Date(dateValue[dateValue.length - 1]);
      const startDate = new Date(dateValue[0]);
      if (
        endDate.setDate(endDate.getDate() - 7) <
        startDate.setDate(startDate.getDate())
      ) {
        return ["All"];
      } else if (
        endDate.setDate(endDate.getDate() - 30) <
        startDate.setDate(startDate.getDate())
      ) {
        return ["W", "M", "All"];
      } else if (
        endDate.setDate(endDate.getDate() - 90) <
        startDate.setDate(startDate.getDate())
      ) {
        return ["W", "M", "3M", "All"];
      } else if (
        endDate.setDate(endDate.getDate() - 365) <
        startDate.setDate(startDate.getDate())
      ) {
        return ["W", "M", "3M", "1Y", "All"];
      } else return ["W", "M", "3M", "1Y", "All"];
    }

    const xAxisTypes = time(response.data.timeseries);

    // const randomColor = [
    //   {color:"#d9ed92"},
    //   {color:"#b5e48c"},
    //   {color:"#99d98c"},
    //   {color:"#76c893"},
    //   {color:"#52b69a"},
    //   {color:"#34a0a4"},
    //   {color:"#168aad"},
    //   {color:"#1a759f"},
    //   {color:"#1e6091"},
    //   {color:"#184e77"},
    //   {color:"#d8f3dc"},
    //   {color:"#b7e4c7"},
    //   {color:"#95d5b2"},
    //   {color:"#74c69d"},
    //   {color:"#52b788"},
    //   {color:"#40916c"},

    // ]

    // const coinValues = Object.entries(coins.data.data).map(
    //   record => {
    //     const randColor = randomColor[Math.floor((Math.random() * 10) + 1)].color
    //     const shortName = record[1].symbol
    //     const name = record[1].name
    //     const color = randColor
    //     return {shortName, name, color}
    //   },
    // )

    // var coinColors = new Object();
    // var i;
    // for (i = 0; i < coinValues.length; i++) {
    //   coinColors[coinValues[i].shortName] = {name:coinValues[i].name, color:coinValues[i].color}
    // }

    // changelog
    // if (rebalances && rebalances.length > 0) {
    //   const value = Object.values(rebalances.data.rebalances)

    //   const { transform, isEqual, isArray, isObject } = require('lodash')

    //   function difference(origObj, newObj) {
    //     function changes(newObj, origObj) {
    //       let arrayIndexCounter = 0
    //       return transform(newObj, function (result, value, key) {
    //         if (!isEqual(value, origObj[key])) {
    //           let resultKey = isArray(origObj) ? arrayIndexCounter++ : key
    //           result[resultKey] =
    //             isObject(value) && isObject(origObj[key])
    //               ? changes(value, origObj[key])
    //               : value
    //         }
    //       })
    //     }
    //     return changes(newObj, origObj)
    //   }

    //   const originalObject = value[0].portfolio_old_just_coins
    //   const newObject = value[0].portfolio_new_just_coins

    //   const diff = difference(originalObject, newObject)

    //   // sum
    //   function sumObjectsByKey(...objs) {
    //     return objs.reduce((a, b) => {
    //       for (let k in b) {
    //         if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k]
    //       }
    //       return a
    //     }, {})
    //   }
    // }
    // portfolio data graph

    if (response) {
      setResponseData(response);

      setportfolioType(response.data.statistics.portfolio_type);

      const timelineData = Object.entries(response.data.timeseries).map(
        (record) => {
          return { name: record[0], pv: record[1] };
        }
      );

      const latestCoins = Object.values(response.data.dates).pop();
      const coins = latestCoins.coins_holding

      const keysSorted = Object.entries(coins).sort(function(a,b){
        return b[1].percentage_share_of_value_on_this_day - a[1].percentage_share_of_value_on_this_day;
      })

      const totalValue =
        response.data.timeseries[
          Object.keys(response.data.timeseries)[
            Object.keys(response.data.timeseries).length - 1
          ]
        ];

      const coinHoldings = Object.entries(coins).map((x) => {
        i = i + 1;
        if (x[0] !== "statistics") {
          return {
            name: x[0].toLowerCase() + "usd",
            pv: Number(x[1].amount_holding),
            percent: Number(x[1].percentage_share_of_value_on_this_day)
          };
        }
      });

      coinHoldings.sort(function (a, b) {
        return b.percent - a.percent;
      })

      if (totalValue > 0) {
        setLiveData(coinHoldings);

        setCoinNames(
          coinHoldings.map((coin) => {
            return coin.name + "t@kline_1m";
          })
        );
      }

      delete coins.statistics;

      function typePortfolio(type) {
        if (type === "balance10") {
          return "BALANCE-10";
        } else if (type === "progressive10") {
          return "PLATFORM10";
        } else if (type === "aggresive10") {
          return "AGGRESSIVE";
        } else if (type === "dynamic10") {
          return "DYNAMIC-10";
        } else return type;
      }

      const type = typePortfolio(response.data.statistics.portfolio_type);

      var i = -1;
      var letter = null;

      const bitcoinTableData = keysSorted.map((x) => {
        i = i + 1;
        if (type === "alt10") {
          letter = null;
        } else if (type === "top10") {
          letter = null;
        } else {
          letter = type.toUpperCase()[i];
        }
        if (x[0] !== "statistics") {
          const isValueString = isNaN(
            x[1].percentage_share_of_value_on_this_day
          );

          return {
            shortName: letter ? letter : coinColors[x[0]]? coinColors[x[0]].name : 'Coin',
            coins: Number(x[1].amount_holding),
            percent: isValueString
              ? 0
              : Number(x[1].percentage_share_of_value_on_this_day),
            price: Number(x[1].price_usd),
            totalUsd: Number(x[1].total_value_usd),
            color: coinColors[x[0]]? coinColors[x[0]].color : '#000',
            //name: coinColors[x[0]].name,
            total: totalValue,
          };
        } else {
          return {
            shortName: letter ? letter : coinColors[x[0]]? coinColors[x[0]].name : 'Coin',
            coins: Number(x[1].amount_holding.toFixed(2)),
            percent: Number(0),
            price: Number(0),
            totalUsd: Number(0),
            color: coinColors[x[0]]? coinColors[x[0]].color : '#000',
            total: totalValue,
          };
        }
      });

      const change = response.data.statistics
        .portfolio_value_change_from_buying_usd
        ? response.data.statistics.portfolio_value_change_from_buying_usd
        : 0;
      const change_percentage = response.data.statistics
        .total_portfolio_value_change_from_buying_percentage
        ? response.data.statistics
            .total_portfolio_value_change_from_buying_percentage
        : 0;
      setDates(response.data.timeseries);
      setInvested(response.data.statistics.invested);
      setCurrentBalance(totalValue);
      setCurrentChange(change);
      setCurrentChangePercentage(change_percentage);
      setBitcoinData(bitcoinTableData)
      setPortfolioRebalanced(bitcoinTableData);
      // tu som pridal prvu hodnotu timeline lebo v response nevidim datum ku invested :/
      timelineData.unshift({
        name: timelineData[0].name,
        pv: response.data.statistics.invested,
      });
      setCurrentYearUntilToday(
        response.data.statistics
          .portfolio_change_since_first_day_of_current_year_until_today_usd
      );
      setChartData(timelineData);
      filterChartData(timelineData);
      setTimeValues(xAxisTypes);
      setIsLoading(false);
    }
  }, []);

  const filterChartData = (data) => {
    const dateValue = Object.entries(dates).map((date) => {
      return date[0];
    });
    const endDate = new Date(dateValue[dateValue.length - 1]);
    var startDate = new Date();
    if (chartAxisType === "W") {
      startDate.setDate(endDate.getDate() - 7);
      setChartAxisValues(getAxisWeek(endDate));
    } else if (chartAxisType === "M") {
      startDate.setDate(endDate.getDate() - 30);
      setChartAxisValues(getAxisMonth(startDate, endDate));
    } else if (chartAxisType === "3M") {
      startDate.setDate(endDate.getDate() - 90);
      setChartAxisValues(getAxis3Month(startDate));
    } else if (chartAxisType === "1Y") {
      startDate.setDate(endDate.getDate() - 365);
      setChartAxisValues(getAxisYear(endDate));
    } else if (chartAxisType === "Live") {
      startDate.setDate(endDate.getDate() - 365);
      //setChartAxisValues(getAxisYear(endDate))
      //setFilteredChartData(messages);
      return;
    } else {
      setChartAxisValues([data[0].name, data[data.length - 1].name]);
      setFilteredChartData(data);
      return;
    }
    const result = data.filter((x) => {
      var time = new Date(x.name);
      return startDate < time && time < endDate;
    });
    setFilteredChartData(result);
  };

  useEffect(() => {
    loadData();
  }, [loadData]);

  useEffect(() => {
    if (chartData) {
      filterChartData(chartData);
    }
  }, [chartAxisType]);

  const handleClick = (time) => {
    if (!time) {
      setCoinError(
        "You clicked outside of the graph! Please select date from the graph."
      );
      return;
    }

    const newDate = new Date(time);
    var dd = String(newDate.getDate()).padStart(2, "0");
    var mm = String(newDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    var yyyy = newDate.getFullYear();

    const formatedDate = mm + "/" + dd + "/" + yyyy;

    setCurrentTime(time);
    setCurrentDate(formatedDate);

    client.close();
  };

  const resetDate = () => {
    window.location.reload();
  };

  return (
    <>
      {isLoading ? (
        <Box flex column>
          <PortfolioChartWrapper p={30} mb={40} mt={20}>
            <Box flex justify="space-around" mb={20}>
              <LoadingSkeleton variant="rect" width={300} height={10} />
              <LoadingSkeleton variant="rect" width={300} height={10} />
              <LoadingSkeleton variant="rect" width={300} height={10} />
            </Box>
            <LoadingSkeleton variant="rect" height={300} width="100%" />
          </PortfolioChartWrapper>
          <PortfolioTableWrapper p={30} mb={40} flex={isDesktop}>
            <Box flex={isTablet} justify={isTablet && "space-around"}>
              <Box pr={30} mt={50} acenter flex column>
                <LoadingSkeleton variant="circle" width={250} height={250} />
              </Box>
            </Box>
            <Box flex column grow>
              <LoadingSkeleton variant="rect" height={600} width="100%" />
            </Box>
          </PortfolioTableWrapper>
        </Box>
      ) : (
        <>
          <PortfolioChartWrapper p={30} mb={40} mt={20}>
            <Box>
              <table>
                <thead>
                  <tr>
                    <th width={200}>Total value</th>
                    {portfolioType !== "guarantee" && <th>Change from buying</th>}
                    <th>
                      <Box flex justify="flex-end" acenter wrap={1}>
                        {/* <Text mr={20} color="black" bold fs={13}>
                          ●&nbsp;Purchase
                        </Text> */}
                        {TimeValues.map((item) => (
                          <TableBtn
                            text={item}
                            onClick={() => setChartAxisType(item)}
                            active={chartAxisType === item}
                            key={item}
                          />
                        ))}
                        <LiveBtn
                          text="Live"
                          onClick={() => setChartAxisType("Live")}
                          active={chartAxisType === "Live"}
                          key="Live"
                        />
                      </Box>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <Text fs={31} light>
                        ${currentBalance.toFixed(2)}
                      </Text>
                      <Text
                        color={currentYearUntilToday > 0 ? currentYearUntilToday.toFixed(0) > 0 ? 'primary' : 'grey' : 'grey'}
                        fs={31}
                        light
                        mr={10}
                        style={{fontSize:'14px'}}
                      >
                        This year: ${currentYearUntilToday > 0 ? currentYearUntilToday.toFixed(0) : currentYearUntilToday < 0 ? currentYearUntilToday.toFixed(0) : '0'}
                      </Text>
                    </td>
                    <td>
                      {portfolioType !== "guarantee" && <Box>
                        <Text color="grey" fs={31} light>
                          ${currentChange.toFixed(2)}
                        </Text>
                        <Text
                          color={ChangePercentage > 0 ? ChangePercentage.toFixed(0) > 0 ? 'primary' : 'grey' : 'grey'}
                          fs={31}
                          light
                          mr={10}
                          style={{fontSize:'14px'}}
                        >
                          {ChangePercentage > 0 ? ChangePercentage.toFixed(0) : ChangePercentage < 0 ? ChangePercentage.toFixed(0) : '0'}%
                        </Text>
                      </Box>}
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
            <Box mt={50} flex column>
              <PortfolioChart
                data={filteredChartData}
                xAxis={chartAxisValues}
                mobile={false}
                handleClick={handleClick}
              />
            </Box>
          </PortfolioChartWrapper>

          <PortfolioChartMobileWrapper p={30} mb={40} mt={20}>
            <Box>
              <table>
                <thead>
                  <tr>
                    <th>Total value</th>
                  </tr>
                  <tr>
                    <th>
                      <Text fs={31} light pb={20}>
                        ${currentBalance.toFixed(2)}
                      </Text>
                    </th>
                  </tr>
                  <tr></tr>
                  <tr>
                    <th>Change from buying</th>
                  </tr>
                  <tr>
                    <td>
                      <Box flex pb={15}>
                        <Text color={ChangePercentage > 0 ? ChangePercentage.toFixed(0) > 0 ? 'primary' : 'grey' : 'grey'} fs={31} light mr={10}>
                          {ChangePercentage > 0 ? ChangePercentage.toFixed(0) : ChangePercentage < 0 ? ChangePercentage.toFixed(0) : '0'}%
                        </Text>
                        <Text color="grey" fs={31} light>
                          ${currentChange.toFixed(2)}
                        </Text>
                      </Box>
                    </td>
                  </tr>
                </thead>
              </table>
            </Box>
            <Box mt={55} flex column>
              <Box flex justify="space-around" acenter wrap={1} pb={30}>
                {TimeValues.map((item) => (
                  <TableBtn
                    text={item}
                    onClick={() => setChartAxisType(item)}
                    active={chartAxisType === item}
                    key={item}
                  />
                ))}
                <LiveBtn
                  text="Live"
                  onClick={() => setChartAxisType("Live")}
                  active={chartAxisType === "Live"}
                  key="Live"
                />
              </Box>
              <PortfolioChart
                data={filteredChartData}
                xAxis={chartAxisValues}
                mobile={true}
                handleClick={handleClick}
              />
            </Box>
          </PortfolioChartMobileWrapper>

          <Box flex={isDesktop} justify="space-between">
            <Text fs={isDesktop ? 20 : 16} mb={15} lh={30}>
              Portfolio Structure
            </Text>
            {coinError ? (
              <StyledAlert severity="warning">{coinError}</StyledAlert>
            ) : (
              <Box flex={isDesktop} align="center">
                {currentTime && (
                  <>
                    <Text mr={15} mb={15} lh={30}>
                      Selected date: <b>{currentDate}</b>
                    </Text>
                    <Button
                      width={!isDesktop}
                      mb={15}
                      onClick={resetDate}
                      text="Reset Date"
                    />
                  </>
                )}
              </Box>
            )}
          </Box>
          <PortfolioTableWrapper mb={40} flex={isDesktop}>
            <Box flex={isTablet} justify={isTablet && "space-around"}>
              <PortfolioGraphMobileWrapper>
                <PortfolioGraph
                  data={portfolioRebalanced}
                  total={currentBalance}
                  mobile={true}
                />
              </PortfolioGraphMobileWrapper>
              <PortfolioGraphWrapper>
                <PortfolioGraph
                  data={portfolioRebalanced}
                  total={currentBalance}
                  mobile={false}
                />
              </PortfolioGraphWrapper>
              {/* <Box pr={30} mt={50} acenter flex column>
                <Text color="black" bold lh={30}>
                  Calculate the preliminary <br />
                  selling price of the portfolio
                </Text>
                <Button text="I want to calculate" mt={20} />
              </Box> */}
            </Box>
            {portfolioType !== 'guarantee' ?
            <PortfolioTableMobileWrapper flex column grow>
              <Table
                headers={[
                  "Currency",
                  "Percentage Share",
                  "Price USD",
                  "Total Value USD",
                  "Holding",
                ]}
                data={portfolioRebalanced}
                config={{
                  columns: [
                    {
                      label: "name",
                      path: (x) => (
                        <Box flex acenter>
                          <Icon color={x.color} mr={10} w={24} h={24}>
                            <MonetizationOn />
                          </Icon>
                          <Text mr={5}>{x.shortName}</Text>
                          <Text color={x.color}>{x.name}</Text>
                        </Box>
                      ),
                    },
                    {
                      label: "percentage",
                      path: (x) => <>{x.percent.toFixed(2)}%</>,
                    },
                    {
                      label: "holding",
                      path: (x) => <>${x.price.toFixed(2)}</>,
                    },
                    {
                      label: "diff",
                      path: (x) => <>${x.totalUsd.toFixed(2)}</>,
                    },
                    { label: "value", path: (x) => <>{x.coins}</> },
                  ],
                }}
              />
              {/* <Button fullWidth text="Show more" secondary lh={20} /> */}
            </PortfolioTableMobileWrapper>
              :
            <PortfolioTableMobileWrapper flex column grow>
              <Table
                headers={[
                  "Currency",
                  "Percentage Share",
                  "Total Value USD",
                ]}
                data={portfolioRebalanced}
                config={{
                  columns: [
                    {
                      label: "name",
                      path: (x) => (
                        <Box flex acenter>
                          <Icon color={x.color} mr={10} w={24} h={24}>
                            <MonetizationOn />
                          </Icon>
                          <Text mr={5}>{x.shortName}</Text>
                          <Text color={x.color}>{x.name}</Text>
                        </Box>
                      ),
                    },
                    {
                      label: "percentage",
                      path: (x) => <>{x.percent.toFixed(2)}%</>,
                    },
                    {
                      label: "diff",
                      path: (x) => <>${x.totalUsd.toFixed(2)}</>,
                    },
                  ],
                }}
              />
              {/* <Button fullWidth text="Show more" secondary lh={20} /> */}
            </PortfolioTableMobileWrapper>
            }
          </PortfolioTableWrapper>
        </>
      )}
    </>
  );
};

function useMessages() {
  const [messages, setMessages] = useState();

  useEffect(() => {
    function handleMessage(message) {
      let mes = JSON.parse(message);
      // set right object for chart here
      if (mes.k) {
        setMessages({
          name: Date.now(),
          pv: mes.k.c,
          coin: mes.s.toLowerCase(),
        });
      }
    }
    client.on(handleMessage);
    return () => client.off(handleMessage);
  }, [messages, setMessages]);

  return messages;
}
