import styled from "styled-components/macro"

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0 10px;
  border-collapse: separate;
  white-space: nowrap;
  min-width: 400px;
  table {
    white-space: nowrap;
   }
`

export const StyledTr = styled.tr`
  height: 70px;
  min-width: 400px;
`

export const StyledTd = styled.td`
  border: 1px solid #ebeaea;
  border-style: solid none;

  &:first-child {
    border-left-style: solid;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  &:last-child {
    border-right-style: solid;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
  }
`
