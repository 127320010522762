import { Box } from "components/Box"
import { Text } from "components/Text"
import { HoverItemWrapper } from "./styled"

export const HoverItem = ({ data }) => (
  <HoverItemWrapper p={15}>
    <Box acenter flex column>
      <Box flex>
        <Text color="grey" mr={5}>
          Date:
        </Text>
        <Text>{data.name}</Text>
      </Box>
      <Box flex>
        <Text color="grey" mr={5}>
          Portfolio
        </Text>
        <Text>{Number(data.pv).toFixed(2)}</Text>
      </Box>
    </Box>
  </HoverItemWrapper>
)
