import { Table } from 'components/Table'
import { Text } from 'components/Text'
import { ChangelogTableWrapper } from './styled'

export const Withdrawals = (props) => {
  if (props.value) {
    return (
      <>
        <Text fs={20} mb={15} lh={30}>
          Withdrawals
        </Text>
        <ChangelogTableWrapper>
          <Table
            headers={['Date', 'Value']}
            data={props.value}
            config={{
              columns: [
                {
                  label: 'name',
                  path: (x) => <>{x.date}</>,
                },
                {
                  label: 'percentage',
                  path: (x) => (
                    <>
                      {x.value > 0 ? -x.value : x.value}{' '}
                      {x.value !== 'full withdrawal' && '$'}
                    </>
                  ),
                },
              ],
            }}
          />
        </ChangelogTableWrapper>
      </>
    )
  } else {
    return <></>
  }
}
